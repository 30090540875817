export const environment = {
  production: false,
  apiUrl: 'https://api-read-dev.nvxportal.com/api',
  eventSourceUrl: 'https://api-dev.nvxportal.com',
  appUrl: '',
  portsOrganizationId: '',
  msalConfig: {
    apiScopes: ['openid', 'https://neelevatcustomer.onmicrosoft.com/agent-portal-api/read.all'],
    clientId: '17725e8f-7220-4274-abc1-c0734c06c715',
    domain: 'login.lspportal.com',
    tenantId: '010952f3-67da-4af2-94e3-e000fd08b328',
    policies: {
      signUpSignIn: 'b2c_1_dev_signin',
      resetPassword: 'b2c_1_reset_custom',
      editProfile: 'b2c_1_edit_profile',
    },
  },
};
